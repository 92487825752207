<template>
	<div class="export">
		<div class="body">
			<div class="filter">
				<div class="left">
					<p v-show="current_user.app_admin==1" class="filter-company" @click="open('show')">
						<span class="company-name">{{companyList.find(one=>one.dept_id==filter.dept_id)?.corp_name}}</span>
					</p>
					<span class="arrow icon-2"></span>
				</div>
				<div class="right">
					<span class="icon" @click="open('filter_time_show')">{{filter.time[0]}}~{{filter.time[1]}}</span>
				</div>
			</div>
			<div class="list">
				<div class="list-one" @click="toExport('exportOne')">
					<span class="list-one-icon color_1 icon-24"></span>
					<span class="list-one-title">导出自行检测报表</span>
					<span class="arrow icon-6"></span>
				</div>
				<div class="list-one" @click="toExport('exportTwo')">
					<span class="list-one-icon color_2 icon-25"></span>
					<span class="list-one-title">导出环境统计报表（气）</span>
					<span class="arrow icon-6"></span>
				</div>
				<div class="list-one" @click="toExport('exportThree')">
					<span class="list-one-icon icon-26"></span>
					<span class="list-one-title">导出环境统计报表（水）</span>
					<span class="arrow icon-6"></span>
				</div>
			</div>
		</div>
		<popup v-model="filter.show" title="企业列表">
			<template #content>
				<div class="company-list">
					<p class="list-one" :class="{selected:item.dept_id==filter.dept_id}" v-for="item in companyList" @click="clickItem(item)">
						<span class="corp-name">{{item.corp_name}}</span>
					</p>
					<no-data v-show="companyList.length==0"></no-data>
				</div>
			</template>
		</popup>
		<popup v-model="filter.filter_time_show" title="时间筛选" height="2rem">
			<template #content>
				<div class="datetime">
					<span class="placeholder" @click="showTimePicker(0)" v-if="!filter.time[0]">起始时间</span>
					<div class="time" @click="showTimePicker(0)" v-else>
						<span class="month_day_time">{{time_filter(0,'time')}}</span>
						<div class="week_year">
							<p class="week">{{time_filter(0,'week')}}</p>
							<p class="year">{{time_filter(0,'year')}}</p>
						</div>
					</div>
					<span class="interval"></span>
					<span class="placeholder" @click="showTimePicker(1)" v-if="!filter.time[1]">截止时间</span>
					<div class="time" @click="showTimePicker(1)" v-else>
						<span class="month_day_time">{{time_filter(1,'time')}}</span>
						<div class="week_year">
							<p class="week">{{time_filter(1,'week')}}</p>
							<p class="year">{{time_filter(1,'year')}}</p>
						</div>
					</div>
				</div>
			</template>
		</popup>
		<popup v-model="filter.time_show" height="38vh">
			<template #content>
				<van-datetime-picker
						v-model="filter.select_time.value"
						title="选择时间"
						@confirm="confirm"
						@cancel="cancel"
						:min-date="filter.select_time.min_date"
						:max-date="filter.select_time.max_date"
				/>
			</template>
		</popup>
		<p class="van-safe-area-bottom"></p>
	</div>
</template>

<script>
	import {ref,reactive,toRefs,defineComponent,onMounted,computed,nextTick} from 'vue'
	import {useRouter,useRoute} from "vue-router"
	import {Dialog,Toast} from "vant"
	import api from "@/api/methods/index"
	import myPublic from "@/utils/public"
	import {useStore} from "vuex"
	import qs from 'qs';
	import * as dd from 'dingtalk-jsapi';
	export default defineComponent({
		name: "export",
		setup(props, ctx) {
			const store = useStore();
			const input = ref(null);
			const state = reactive({
				companyList:[],
				list:{
					page:1,
					page_size:12,
					total:0,
					next_time:Date.now(),
					data:[]
				},
				filter:{
					dept_id:'',
					device_id:[],
					mapping_name:[],
					type:[],
					name:'',
					time:['',''],
					select_time:{
						type:0,
						value:'',
						min_date:'',
						max_date:''
					},
					search_show:false,
					time_show:false,
					filter_time_show:false,
					filterShow:false,
					show:false,
				},
				loading:false
			});
			const current_user = computed(()=>{
				return store.state.user.current_user
			});
			function color_change(type) {
				if(type==2011){
					return {
						color:'#1eaf93',
						backgroundColor:'#e9f7f5'
					}
				}else if(type==2061){
					return {
						color:'#2aa0f7',
						backgroundColor:'#eaf4fe'
					}
				}else{
					return {
						color:'#ff872e',
						backgroundColor:'#fff3eb'
					}
				}
			}
			function click_search() {
				state.filter.search_show=true;
				nextTick(()=>{
					input.value.focus();
				});
			}
			function weekFormat(value) {
				switch(value){
					case 1:
						return "周一";
					case 2:
						return "周二";
					case 3:
						return "周三";
					case 4:
						return "周四";
					case 5:
						return "周五";
					case 6:
						return "周六";
					case 0:
						return "周日";
				}
			}
			function time_filter(index,type){
				let time = state.filter.time[index];
				if(type=='week'){
					return time ? weekFormat(new Date(time.replace(/-/g, '/')).getDay()) : ''
				}else if(type=='year'){
					return time ? new Date(time.replace(/-/g, '/')).getFullYear() : '';
				}else if(type=='time'){
					return time?time.substring(5,16):'';
				}
			}
			function showTimePicker(type) {
				state.filter.select_time.value = state.filter.time[type]?new Date(state.filter.time[type]):new Date();
				state.filter.select_time.type = type;
				if (type==0){
					state.filter.select_time.min_date = calc_interval(state.filter.select_time.value,-10,'year');
					state.filter.select_time.max_date = calc_interval(state.filter.select_time.value,10,'year');
					if(state.filter.time[1]){
						state.filter.select_time.max_date = new Date(state.filter.time[1]);
					}
				}
				if (type==1){
					state.filter.select_time.min_date = calc_interval(state.filter.select_time.value,-10,'year');
					state.filter.select_time.max_date = calc_interval(state.filter.select_time.value,10,'year');
					if(state.filter.time[0]){
						state.filter.select_time.min_date = new Date(state.filter.time[0]);
					}
				}
				state.filter.time_show = true;
			}
			function timeSelect(time) {
				time = time.replace(/-/g, '/');
				let start_time = '';
				let end_time = '';
				if (filter.select_time.type === 1) {
					start_time = filter.time[0].replace(/-/g, '/');
					if (new Date(time).getTime() > new Date(start_time).getTime()) {
						filter.time[1]=time.replace(/\//g, '-');
					} else {
						end_time = myPublic.dateFormat.format(time, 'YYYY-MM-DD HH:mm').replace(/-/g, '/');
						start_time = myPublic.dateFormat.format(new Date(end_time.replace(/-/g, '/')).getTime() - 3600000, 'YYYY-MM-DD HH:mm').replace(/-/g, '/');
						filter.time[1]=end_time.replace(/\//g, '-');
						filter.time[0]=start_time.replace(/\//g, '-');
					}
				} else if (filter.select_time.type === 0) {
					if (!filter.time[1]) {
						end_time = new Date(new Date(time).getTime() + 3600000).toString();
						end_time = myPublic.dateFormat.format(end_time, 'YYYY-MM-DD HH:mm').replace(/-/g, '/');
					} else {
						end_time = filter.time[1].replace(/-/g, '/');
						start_time = filter.time[0].replace(/-/g, '/');
						if (new Date(time).getTime() > new Date(end_time).getTime()) {
							let subtraction = new Date(time).getTime() - new Date(start_time).getTime();
							end_time = new Date(new Date(end_time).getTime() + subtraction).toString();
							end_time = myPublic.dateFormat.format(end_time, 'YYYY-MM-DD HH:mm').replace(/-/g, '/');
						}
					}
					filter.time[0]=time.replace(/\//g, '-');
					filter.time[1]=end_time.replace(/\//g, '-');
				}
			}
			function calc_interval(time,interval,type='year') {
				let dateTime = {
					year:0,
					month:0,
					date:0,
					hour:0,
					minute:0,
				};
				dateTime.year = new Date(time).getFullYear();
				dateTime.month = new Date(time).getMonth();
				dateTime.date = new Date(time).getDate();
				dateTime.hour = new Date(time).getHours();
				dateTime.minute = new Date(time).getMinutes();
				for (let i in dateTime){
					if(type==i){
						dateTime[i] += interval
					}
				}
				return new Date(dateTime.year,dateTime.month,dateTime.date,dateTime.hour,dateTime.minute);
			}
			function confirm(val){
				state.filter.time[state.filter.select_time.type] = myPublic.dateFormat.format(val,'YYYY/MM/DD HH:mm');
				if(state.filter.select_time.type==0){
					if(!state.filter.time[1]){
						state.filter.time[1] = myPublic.dateFormat.format(calc_interval(state.filter.time[0],1,'month'),'YYYY/MM/DD HH:mm');
					}else{
						if(Date.parse(state.filter.time[1])-Date.parse(state.filter.time[0])>30*24*60*60*1000){
							state.filter.time[1] = myPublic.dateFormat.format(calc_interval(state.filter.time[0],1,'month'),'YYYY/MM/DD HH:mm');
						}
					}
				}
				if(state.filter.select_time.type==1){
					if(!state.filter.time[0]){
						state.filter.time[0] = myPublic.dateFormat.format(calc_interval(state.filter.time[1],-1,'month'),'YYYY/MM/DD HH:mm');
					}else{
						if(Date.parse(state.filter.time[1])-Date.parse(state.filter.time[0])>31*24*60*60*1000){
							state.filter.time[0] = myPublic.dateFormat.format(calc_interval(state.filter.time[1],-1,'month'),'YYYY/MM/DD HH:mm');
						}
					}
				}
				state.filter.time_show = false;
			}
			function cancel(){
				state.filter.time_show = false;
			}
			function clear(){
				state.filter.time[1] = myPublic.dateFormat.format(new Date(),'YYYY/MM/DD HH:mm');
				state.filter.time[0] = myPublic.dateFormat.format(calc_interval(state.filter.time[1],-7,'date'),'YYYY/MM/DD HH:mm');
				state.filter.filter_time_show = false;
			}
			function getCompanyList(page=1){
				let p ={
					page:page,
					page_size:999
				};
				return api.company.getCompanyList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							state.companyList = res.data.list;
							if(!state.filter.dept_id){
								if(current_user.value.dept_id){
									state.filter.dept_id = current_user.value.dept_id;
								}else{
									state.filter.dept_id = res.data.list[0]?.dept_id;
								}
							}
						}
					}
				})
			}
			function fileName(apiType='exportOne') {
				switch (apiType) {
					case "exportOne":
						return "自行检测报表.xlsx";
					case "exportTwo":
						return "环境统计报表（气）.xlsx";
					case "exportThree":
						return "环境统计报表（水）.xlsx";
				}
			}
			function toExport(apiType='exportOne'){
				let p ={

				};
				if(state.filter.dept_id){
					p.dept_id = state.filter.dept_id;
				}
				if(state.filter.time[0]||state.filter.time[1]){
					p.start_time = state.filter.time[0];
					p.end_time = state.filter.time[1];
				}
				state.loading = true;
				let token = myPublic.getStorage('cf-token');
				p["access_token"] = token;
				const url = `${env.API_HOST}/${api.export_file[apiType]()}?${qs.stringify(p)}`;
				const isDingtalk = navigator && /DingTalk/.test(navigator.userAgent);
				if(isDingtalk){
					dd.biz.util.openLink({
						url: url,//要打开链接的地址
						onSuccess: function (result) {
							/**/
						},
						onFail: function (err) {
						}
					})
				}else{
					window.open(url,"_blank");
				}
			}
			function open(type){
				state.filter[type] = true;
			}
			function clickItem(item){
				if(state.filter.dept_id != item.dept_id){
					state.filter.dept_id = item.dept_id;
				}
				state.filter.show = false;
			}
			onMounted(async ()=>{
				state.filter.time[1] = myPublic.dateFormat.format(new Date(),'YYYY/MM/DD HH:mm');
				state.filter.time[0] = myPublic.dateFormat.format(calc_interval(state.filter.time[1],-7,'date'),'YYYY/MM/DD HH:mm');
				await getCompanyList();
			});
			return {
				...toRefs(state),
				input,
				current_user,
				showTimePicker,
				time_filter,
				confirm,
				cancel,
				toExport,
				click_search,
				color_change,
				open,
				clickItem
			};
		}
	})
</script>
<style scoped lang="less">
	.export{
		position: relative;
		height: 100vh;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background-color: #fff;
		.body{
			flex:1;
			display: flex;
			flex-direction: column;
			background-color: #fff;
			overflow: hidden;
			.filter{
				position: relative;
				display: flex;
				align-items: center;
				height: .4rem;
				flex-shrink: 0;
				padding: 0 .1rem;
				.left{
					flex: 1;
					display: flex;
					align-items: center;
					overflow: hidden;
					.filter-company{
						min-width: 0;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						cursor: pointer;
					}
				}
				.right{
					margin-left: .1rem;
					.icon{
						cursor: pointer;
						margin-left: .15rem;
					}
					.color{
						color: #3396fb;
					}
				}
			}
			.list{
				flex: 1;
				overflow-y: auto;
				padding: .1rem;
				background-color: #eff2f4;
				.list-one{
					display: flex;
					align-items: center;
					background-color: #fff;
					border-radius: 8px;
					overflow: hidden;
					padding: .1rem;
					margin-bottom: .1rem;
					cursor: pointer;
					&:active{
						opacity: .8;
					}
					.list-one-icon{
						width: .5rem;
						height: .5rem;
						line-height: .5rem;
						border-radius: 16px;
						text-align: center;
						background-color: #eaf6ff;
						color: #3399fb;
						font-size: .2rem;
					}
					.color_1{
						background-color: #fff3eb;
						color: #ff872e;
					}
					.color_2{
						background-color: #e9f7f5;
						color: #1eaf93;
					}
					.list-one-title{
						flex: 1;
						font-weight: bolder;
						padding-left: .1rem;
					}
				}
			}
		}
		.company-list{
			height: 100%;
			padding: 0 .25rem;
			overflow-y: auto;
			.list-one{
				display: flex;
				align-items: center;
				height: .4rem;
				border-bottom: 1px solid #efefef;
				.corp-name{
					flex: 1;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
			.selected{
				.corp-name{
					color: #2aa0f7;
				}
			}
		}
		.datetime{
			display: flex;
			align-items: center;
			height: 1rem;
			padding: 0 .25rem;
			.time{
				flex: 1;
				display: inline-flex;
				justify-content: center;
				.month_day_time{
					float: left;
					width: .90rem;
					height: .14rem;
					border-radius:.04rem;
					text-align: center;
					line-height: .14rem;
					background-color:#3396fb;
					color: #fff;
					padding: .08rem 0;
					font-weight: 100;
					font-size: .14rem;
				}
				.week_year{
					float: left;
					text-align: center;
					margin-left: .005rem;
					.week{
						line-height: .16rem;
						font-size: .13rem;
					}
					.year{
						line-height: .15rem;
						font-size: .15rem;
					}
				}
			}
			.interval{
				position: relative;
				float: left;
				width: .5rem;
				text-align: center;
				&::after{
					content:'';
					position: absolute;
					left: 0;
					top: 0;
					width: .5rem;
					border-top:2px solid #ccc;
				}
			}
			.placeholder{
				flex: 1;
				display: inline-flex;
				justify-content: center;
				color: #999;
				font-size: .186rem;
			}
		}
	}
	.add-user{
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		.content{
			flex: 1;
			overflow-y: auto;
			.col{
				padding: .05rem .25rem .15rem .25rem;
				.col-name{

				}
				.col-content{
					padding-top: .05rem;
					display: flex;
					overflow: hidden;
					.col-content-top{
						flex: 1;
						display: flex;
						overflow: hidden;
						margin-bottom: .1rem;
					}
				}
			}
			.row{
				display: flex;
				align-items: center;
				justify-content: space-between;
				min-height: .2rem;
				border-bottom: 1px solid #efefef;
				overflow: hidden;
				margin: 0 .25rem;
				padding: .1rem 0;
				font-size: .12rem;
				.row-name{
					width: .8rem;
					color: #949aa8;
				}
				.row-content{
					flex: 1;
					display: inline-flex;
					flex-wrap: wrap;
				}
			}
			.vertical{
				flex-direction: column;
			}
			.notice-type{
				display: flex;
				padding-top: .1rem;
				.notice-one{
					display: inline-flex;
					align-items: center;
					justify-content: center;
					width: 1rem;
					height: .32rem;
					border-radius: .04rem;
					background-color: #eef2f5;
					color: #999;
					cursor: pointer;
				}
				.selected{
					background-color: #2aa0f7;
					color: #fff;
				}
			}
			.textarea{
				flex: 1;
				padding: 0.1rem;
				border-radius: 0.04rem;
				background-color: #eef2f5;
				resize: none;
			}
		}
		.opt{
			flex-shrink: 0;
			display: flex;
			align-items: center;
			min-height: .5rem;
			border-top: 1px solid #efefef;
			padding: 0 .15rem;;
		}
		.btn{
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: .6rem;
			height: .3rem;
			border-radius: .04rem;
			background-color: #2aa0f7;
			color: #fff;
			font-size: .12rem;
			border: 1px solid transparent;
			cursor: pointer;
			&:active{
				opacity: .8;
			}
		}
		.default{
			background-color: #fff;
			color: #2aa0f7;
			border: 1px solid #2aa0f7;
		}
		.danger{
			color: #f56c6c;
			background: #fff;
			border-color: #fbc4c4;
		}
	}
</style>
